<template>
  <div>
    <svg
      v-if="boolean"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4833 17.5819C11.8011 17.3901 12.1989 17.3901 12.5167 17.5819L16.151 19.7754C16.9083 20.2325 17.8425 19.5535 17.6416 18.692L16.6771 14.5578C16.5928 14.1963 16.7156 13.8178 16.9962 13.5748L20.2121 10.7889C20.8801 10.2101 20.5239 9.11271 19.6432 9.03677L15.4095 8.67169C15.0406 8.63989 14.7195 8.40691 14.5749 8.06612L12.9205 4.16859C12.5757 3.35623 11.4243 3.35623 11.0795 4.16859L9.42511 8.06612C9.28045 8.40691 8.95936 8.63989 8.59051 8.67169L4.35383 9.03703C3.4736 9.11293 3.11704 10.2095 3.78428 10.7886L6.99532 13.5754C7.27505 13.8182 7.39768 14.1957 7.31401 14.5565L6.35429 18.6957C6.15464 19.5567 7.08842 20.2344 7.84518 19.7777L11.4833 17.5819Z"
        :fill="color"
      />
    </svg>
    <svg
      v-else
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5386 19.1333L12.9043 16.9398C12.3482 16.6041 11.6518 16.6041 11.0957 16.9398L7.45763 19.1356C7.26844 19.2498 7.035 19.0803 7.08491 18.8651L8.04463 14.7259C8.19104 14.0945 7.97644 13.4338 7.48691 13.009L4.27588 10.2221C4.10907 10.0774 4.1982 9.80323 4.41827 9.78425L8.65495 9.41892C9.30043 9.36326 9.86234 8.95554 10.1155 8.35917L11.7699 4.46164C11.8561 4.25855 12.1439 4.25855 12.2301 4.46164L13.8845 8.35917C14.1377 8.95554 14.6996 9.36326 15.3451 9.41892L19.5788 9.784C19.7989 9.80298 19.888 10.0773 19.721 10.222L16.5051 13.0079C16.0141 13.4333 15.7992 14.0956 15.9467 14.7282L16.9112 18.8624C16.9614 19.0778 16.7279 19.2475 16.5386 19.1333Z"
        :stroke="color"
        stroke-width="1.5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'StarIcon',
  props: {
    boolean: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: 'var(--settings-registration-current-event-button-background)',
    },
  },
};
</script>
