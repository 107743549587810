<template>
  <div class="main-page-wrapper">
    <slider-component v-if="slides.length" :slides="slides"></slider-component>
    <static-block-component page="home" position="top"></static-block-component>
    <div class="container-fluid home-container home">
      <div
          v-if="upcomingEvents.length"
          class="section_event"
      >
        <div class="text-left section_title">
					<span>
						{{ $t('Upcoming events') }}
					</span>
          <app-link
              :to="{name: 'Upcoming'}"
              class="more-button"
              v-if="!mobileView"
          >
            <div>
              {{ $t('More') }}
            </div>
          </app-link>
        </div>
        <carousel-component
            v-if="!mobileView"
            :item-data="upcomingEvents"
            :displayed-quantity="4"
            :max-per-line="upcomingEvents.length"
            :scrolled-quantity="2"
            :autoscroll-delay="this.$options.CAROUSEL_AUTOSCROLL_SPEED"
            :min-item-width="280"
        >
          <template v-slot:default="{props, index}">
            <div class="p-3 flex-grow-1 d-flex">
              <c-w-event-card
                  :key="props.id"
                  :event="props"
              />
            </div>
          </template>
        </carousel-component>
        <div v-else>
          <c-w-event-card
              v-for="event in upcomingEvents.slice(0, this.$options.MAX_CARDS_FOR_MOBILE)"
              :event="event"
              :key="event.id"
              class="my-2"
          />
          <app-link
              :to="{name: 'Upcoming'}"
              class="more-button__black mt-3"
          >
            <div>
              {{ $t('More') }}
            </div>
          </app-link>
        </div>
      </div>
      <static-block-component page="home" position="middle"></static-block-component>
      <template v-if="packages.length || subscriptions.length" >
        <div class="text-left section_title section_event">
        <span>
          {{ $t('Packages')}} / {{$t('Passes') }}
        </span>
        </div>
        <div class="main-packages-wrapper main-container mb-5">
          <subscription-component
              v-for="item in subscriptions"
              class="d-flex w-100"
              :item="item"
              :key="item.id"
          />
          <package-component
            v-for="item in packages"
            class="d-flex w-100"
            :item="item"
            :key="item.id"
          />
        </div>
      </template>
    </div>
    <static-block-component page="home" position="bottom"></static-block-component>
  </div>
</template>

<script>
import {mapCacheActions} from "vuex-cache";
import {mapState} from "vuex";
import {STREAM_STATUS} from "@/const";
import SliderComponent from "../components/main/SliderComponent";
import StaticBlockComponent from "../../components/StaticBlockComponent";
import CarouselComponent from "../../components/base-components/CarouselComponent";
import UpcomingEventCard from "../components/schedule/UpcomingEventCard";
import AppLink from "../../components/base-components/AppLink";
import ImageComponent from "../../components/base-components/ImageComponent";
import CWEventCard from "@/league/components/EventCards/CWEventCard";
import PackageComponent from "@/league/components/main/PackageComponent";
import SubscriptionComponent from "@/league/components/main/SubscriptionComponent";
import noimage from "../../assets/img/no-image.jpg";

export default {
  name: "HomeScreen",
  PACKAGES_LIMIT: 10,
  MOBILE_VIEW_BREAKPOINT: 768,
  CAROUSEL_AUTOSCROLL_SPEED: 5000,
  MAX_CARDS_FOR_MOBILE: 3,
  components: {
    SubscriptionComponent,
    PackageComponent,
    CWEventCard,
    AppLink,
    UpcomingEventCard, CarouselComponent, SliderComponent, StaticBlockComponent, ImageComponent
  },
  data() {
    return {
      requestUpcomingParams: {
        limit: 12,
        stream_status: [STREAM_STATUS.live, STREAM_STATUS.upcoming]
      },
      requestFeaturedParams: {
        limit: 4,
        is_featured: 1
      },
      slides: [],
      upcomingEvents: [],
      participantsList: [],
      featuredContent: [],
      mobileView: false
    }
  },
  computed:{
    ...mapState({
      packages: state => state.payment.packagesList || [],
      subscriptions: state => state.payment.subscriptionsList || [],
    })
  },
  methods: {
    ...mapCacheActions(['GET_EVENTS_LIST', 'GET_CHANNEL_SLIDES', 'GET_VOD_ITEMS', 'GET_PARTICIPANTS_LIST', 'GET_CHANNEL_SETTINGS', 'GET_SUBSCRIPTIONS_CACHED', 'GET_PACKAGES_CACHED', 'GET_EVENT_CATEGORIES']),
    makeRequest() {
      this.GET_EVENTS_LIST(this.requestUpcomingParams)
          .then(({data}) => {
            this.upcomingEvents = data;
          });
      this.GET_PARTICIPANTS_LIST()
          .then(({data}) => {
            this.participantsList = data;
          });
      this.GET_CHANNEL_SLIDES()
          .then(({data}) => {
            this.slides = data
          });
      this.GET_VOD_ITEMS(this.requestFeaturedParams)
          .then(({data}) => {
            this.featuredContent = data;
          });
      this.GET_PACKAGES_CACHED({limit: this.$options.PACKAGES_LIMIT});
      this.GET_SUBSCRIPTIONS_CACHED({limit: this.$options.PACKAGES_LIMIT});
      this.GET_EVENT_CATEGORIES();
    },
    initOnResize() {
      const cb = () => this.mobileView = document.body.clientWidth <= this.$options.MOBILE_VIEW_BREAKPOINT;
      cb()
      window.addEventListener('resize', cb)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', cb)
      })
    },
    getImage(imageParam) {
      if (typeof imageParam === "string") {
        return this.getImagePath(imageParam)
      } else if (typeof imageParam === "object") {
        const quality = Object.keys(imageParam)[0]
        return this.getImagePath(imageParam[quality])
      }
      return noimage
    }
  },
  created() {
    this.makeRequest();
    this.initOnResize();
  },

}
</script>

<style lang="scss" scoped>
@import "src/assets/css/colors";
@import "src/assets/css/mixins";

#app .main-page-wrapper {
  background-color: var(--settings-body-main-color);
}

.home {
  padding: 0;
}

.featured {
  background-color: $main-background;

  .section_title,
  .more-button {
    color: var(--settings-body-text-color);
  }
}

.event-list {
  padding: 0 15px;
}

.section_event {
  padding: 1rem 2rem;
}

.section_title {
  color: var(--settings-body-text-color);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1rem;
  margin-bottom: .5rem;
  margin-top: .5rem;
  margin-left: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    padding: .5rem 0;
    text-transform: uppercase;
    margin-bottom: .25rem;
  }

  @include media-max(sm) {
    margin-bottom: 2rem;
  }
}

.more-button {
  color: var(--settings-body-text-color);
  transition: all .3s;
  padding: .5rem 1.2rem;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 500;
}

.more-button__black {
  color: $text-secondary;
  font-weight: 600;
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s;

  div {
    background: $button-primary;
    margin: 1rem;
    border-radius: .3rem;
    box-shadow: $card-shadow;
    padding: .5rem;
  }
}
.main-packages-wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  gap: .5rem;
}
</style>