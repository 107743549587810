<template>
    <div class="wrapper">
        <div class="inner-card d-flex flex-column">
            <div class="card-content">
                <div class="time-wrapper"> 
                    <event-status :event="event" class="status"/>
                    {{formatEventDate}}
                </div>
                <p class="timezone">{{timeZoneName}}</p>
                <div
                        v-if="isTwoTeamsEvent"
                        class="teams-logo-wrapper"
                >
                    <image-component
                            :path="getImage(event.participants[0].images)"
                            :alt="event.participants[0].name"
                    />
                    <span>VS</span>
                    <image-component
                            :path="getImage(event.participants[1].images)"
                            :alt="event.participants[0].name"
                    />
                </div>
                <div class="image-wrapper" v-else>
                    <image-component :path="eventImage"
                         :alt="event.name"
                    />
                </div>
                <p class="title" :title="event.name">{{event.name}}</p>
                <p class="venue" :title="event.venue">{{event.venue}}</p>
            </div>
            <event-button :event="event" class="button"/>
        </div>
    </div>
</template>

<script>
  import {MEDIA_TYPE, TIME_ZONES} from "../../../const";
  import noimage from "../../../assets/img/no-image.jpg";
  import EventButton from "../main/EventButton";
  import EventStatus from "./EventStatus";
  import ImageComponent from "../../../components/base-components/ImageComponent";

  export default {
    name: "UpcomingEventCard",
    components: {ImageComponent, EventStatus, EventButton},
    props: {
      event: {
        type: Object
      },
      selectedTimeZone: {
        type: String
      }
    },
    methods:{
      getImage(imageParam){
        if(typeof imageParam === "string"){
          return this.getImagePath(imageParam)
        } else if(typeof imageParam === "object"){
          const quality = Object.keys(imageParam)[0]
          return this.getImagePath(imageParam[quality])
        }
        return noimage
      }
    },
    computed: {
      isTwoTeamsEvent(){
        return this.event.participants.length === 2
      },
      formatEventDate() {
          const {weekday, month, day, year, hh} = this.getFormattedDate({
              ts: this.event.event_utc_ts,
              formatsOptions: {
                  weekday: {weekday: 'short'},
                  month: {month: 'short'},
                  day: {day: '2-digit'},
                  year: {year: 'numeric'},
                  hh: {hour: '2-digit', hour12: true,  minute: '2-digit'}
              },
              timeZone: this.selectedTimeZone
          })
        return `${weekday} ${month} ${day} ${year} ${hh}`
      },
      timeZoneName(){
        return TIME_ZONES.find(({value}) => value === this.selectedTimeZone)?.name || this.selectedTimeZone
      },
      eventImage(){
        let poster = this.event?.media?.find((item) => {
          return Number(item.media_type) === MEDIA_TYPE.image
        });
        return poster?.media_url
      }
    },
  }
</script>

<style scoped lang="scss">
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";

    .wrapper{
        box-shadow: $card-shadow;
        padding: 1.25rem;
        background-color: #fff;
        margin: 5px;
    }
    .inner-card{
        background: $tertiary-background;
        box-shadow: $card-shadow;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    .card-content{
        padding: .64rem;
        color: var(--settings-body-text-color);
        position: relative;
        p{
            margin-bottom: 0;
        }
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .timezone{
        font-weight: 600;
        height: 2rem;
    }
    .image-wrapper{
        width: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-width: 100%;
            object-fit: contain;
            @include ratio(calc(16 / 9));
            width: 100%;
        }
    }
    .teams-logo-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        img{
            width: 40%;
        }
    }
    .title{
        font-weight: 600;
        margin-top: .5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
    }
    .venue{
        line-height: 1rem;
        min-height: 1rem;
    }
    .button{
        display: block;
        background: $main-background;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.2rem;
        text-transform: uppercase;
        padding: .5rem 1rem;
        border-radius: .4rem;
        color: $text-secondary;
        &:hover{
            text-decoration: none;
            color: $text-secondary
        }
    }
    .time-wrapper{
        position: relative;
    }
    .status{
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5rem;
    }
</style>