<template>
	<div class="carousel">
		<div v-if="buttonNeed" class="carousel_navigation">
			<ButtonComponent
				variant="icon"
				class="left_arrow"
				@btn-click="onLeft"
			>
				<single-angle/>
			</ButtonComponent>
			<ButtonComponent
				variant="icon"
				class="right_arrow"
				@btn-click="onRightHandle"
			>
				<single-angle/>
			</ButtonComponent>
		</div>
		<div
			class="carousel_wrapper"
			ref="itemsListWrapper"
			:style="{
				'scroll-behavior': behaviour
			}"
		>
			<div
				class="carousel_items"
				id="carousel_items"
				:style="{
                    'grid-template-columns': `repeat(${maxItemInLine}, calc(100%/${itemsOnScreen}))`,
                }"
				@mouseover="onFocus"
				@mouseleave="onBlur"
			>
				<slot name="before"></slot>
				<slot
					v-for="(item, ind) in scrolledData"
					:props="item"
					:index="ind"
				/>
				<slot name="after"></slot>
			</div>
		</div>
	</div>
</template>

<script>
  import ButtonComponent from "./buttons/ButtonComponent";
  import {throttle} from 'lodash'
  import SingleAngle from "../../assets/img/singleAngle";

  export default {
    name: "CarouselComponent",
    components: {SingleAngle, ButtonComponent},
    props: {
      itemData: {
        type: Array,
        required: true,
      },
      scrolledQuantity: {
        type: Number,
        default: 4
      },
      displayedQuantity: {
        type: Number,
        default: 4
      },
      maxPerLine: {
        type: Number,
        default: 6
      },
      autoscrollDelay: {
        type: Number,
      },
      minItemWidth: {
        type: Number
      },
      needInfinity: {
        type: Boolean,
        default: true
      }
    },
    SCROLL_BEHAVIOUR: {
      SMOOTH: 'smooth',
      AUTO: 'auto'
    },
    data() {
      return {
        scrollLeft: 0,
        itemWidth: null,
        maxScrollLeft: 0,
        autoscrollIntervalId: null,
        itemsOnScreen: this.displayedQuantity,
        beforeDestroyCb: [],
        scrolledData: this.itemData.slice(),
        maxItemInLine: this.maxPerLine,
        behaviour: this.$options.SCROLL_BEHAVIOUR.SMOOTH,
        scrollProcessing: false,
      }
    },
    watch: {
      scrollLeft() {
        this.$refs.itemsListWrapper.scrollLeft = this.scrollLeft;
      },
      itemWidth(newWidth, oldWidth) {
        if (oldWidth) {
          const scrolledQuantity = this.scrollLeft / oldWidth;
          this.setNewLeft(scrolledQuantity * newWidth);
        }

        const newItemsQuantity = parseInt(String(this.$refs.itemsListWrapper.clientWidth / this.minItemWidth))
        const normalizedItemsQuantity = Math.min(newItemsQuantity, this.displayedQuantity)
        this.itemsOnScreen = Math.max(normalizedItemsQuantity, 1)
      },
      itemsOnScreen() {
        this.$nextTick()
            .then(this.onResize)
      }
    },
    computed: {
      normalizedScrolledQuantity() {
        return Math.min(this.scrolledQuantity, this.itemsOnScreen)
      },
      scrolledWidth() {
        return this.itemWidth * this.normalizedScrolledQuantity
      },
      buttonNeed(){
        return this.itemsOnScreen < this.maxItemInLine
      }
    },
    methods: {
      setNewLeft(left) {
        this.scrollLeft = left
      },
      waitForAnimate(cb) {
        const animate = () => {
          window.requestAnimationFrame(() => {
            const ready = Math.floor(this.scrollLeft) === Math.floor(this.$refs.itemsListWrapper.scrollLeft);
            ready ? cb() : animate()
          })
        }
        animate()
      },
      swipeLeft() {
        const newLeft = Math.max(this.scrollLeft - this.scrolledWidth, 0)
        this.setNewLeft(newLeft)
      },
      addItemsRight() {
        const initLength = this.scrolledData.length
        this.scrolledData = [...this.scrolledData, ...this.scrolledData.slice(0, this.normalizedScrolledQuantity)]
        this.maxItemInLine = initLength + this.normalizedScrolledQuantity
      },
      addItemsLeft() {
        const initLength = this.scrolledData.length
        this.scrolledData = [...this.scrolledData.slice(-this.normalizedScrolledQuantity), ...this.scrolledData]
        this.maxItemInLine = initLength + this.normalizedScrolledQuantity
      },
      deleteLeftItems() {
        const newLeft = Math.max(this.scrollLeft - this.scrolledWidth, 0)
        this.setNewLeft(newLeft)
        this.scrolledData = this.scrolledData.slice(this.normalizedScrolledQuantity)
        this.maxItemInLine = this.scrolledData.length;
      },
      deleteRightItems() {
        const newLeft = Math.max(this.scrollLeft - this.scrolledWidth, 0)
        this.setNewLeft(newLeft)
        this.scrolledData = this.scrolledData.slice(0, this.maxPerLine)
        this.maxItemInLine = this.scrolledData.length;
      },
      removeItemsLeft() {
        this.behaviour = this.$options.SCROLL_BEHAVIOUR.AUTO
        this.$nextTick()
            .then(this.deleteLeftItems)
            .then(() => {
              this.behaviour = this.$options.SCROLL_BEHAVIOUR.SMOOTH
            })
      },
      removeItemsRight() {
        this.deleteRightItems()
      },
      infinityRight() {
        if (this.scrollProcessing) return;
        this.scrollProcessing = true;
        this.addItemsRight()
        this.$nextTick()
            .then(this.getMaxScrollLeft)
            .then(this.swipeRight)
            .then(() => {
              this.waitForAnimate(() => {
                this.removeItemsLeft();
                this.scrollProcessing = false
              })
            });
      },
      infinityLeft() {
        if (this.scrollProcessing) return;
        this.scrollProcessing = true;
        this.behaviour = this.$options.SCROLL_BEHAVIOUR.AUTO
        this.$nextTick()
            .then(() => {
              this.addItemsLeft();
              this.setNewLeft(this.scrollLeft + this.scrolledWidth)
            })
            .then(() => {
              this.behaviour = this.$options.SCROLL_BEHAVIOUR.SMOOTH
            })
            .then(this.swipeLeft)
            .then(() => {
              this.waitForAnimate(()=>{
                this.removeItemsRight()
                this.scrollProcessing = false
              })
            })
      },
      swipeRight() {
        const newLeft = Math.min(this.scrollLeft + this.scrolledWidth, this.maxScrollLeft)
        this.setNewLeft(newLeft)
      },
      onRight() {
        if (this.maxScrollLeft === 0) return;
        if (this.needInfinity) {
          this.infinityRight();
          return;
        }
        if (this.scrollLeft === this.maxScrollLeft) {
          this.setNewLeft(0);
          return;
        }
        this.swipeRight()
      },
      onLeft() {
        if (!this.needInfinity && !this.scrollLeft) return;
        this.stopAutoscroll();
        this.needInfinity ? this.infinityLeft() : this.swipeLeft();
        this.initAutoscroll()
      },
      onRightHandle() {
        this.stopAutoscroll()
        this.onRight()
        this.initAutoscroll()
      },
      getItemWidth() {
        const wrapperWidth = this.$refs.itemsListWrapper?.clientWidth;
        this.itemWidth = wrapperWidth / this.itemsOnScreen;
      },
      getMaxScrollLeft() {
        this.maxScrollLeft = this.$refs.itemsListWrapper.scrollWidth - this.$refs.itemsListWrapper.clientWidth;
      },
      onResize() {
        this.onFocus();
        this.getItemWidth();
        this.getMaxScrollLeft();
        this.onBlur();
      },
      onFocus() {
        this.stopAutoscroll()
      },
      onBlur() {
        this.initAutoscroll();
      },
      stopAutoscroll() {
        this.autoscrollIntervalId && clearInterval(this.autoscrollIntervalId)
      },
      initAutoscroll() {
        if (!this.autoscrollDelay) return;
        this.stopAutoscroll();
        this.autoscrollIntervalId = setInterval(() => {
          this.onRight()
        }, this.autoscrollDelay);
        this.cleanOnDestroy(() => {
          clearInterval(this.autoscrollIntervalId)
        })
      },
      initFirstCalculate() {
        const el = this.$refs.itemsListWrapper.firstChild;

        if (el.hasChildNodes()) {
          this.onResize()
        } else {
          const observer = new MutationObserver(this.onResize)
          observer.observe(el, {
            childList: true,
          })
          this.cleanOnDestroy(() => observer.disconnect())
        }
      },
      cleanOnDestroy(cb) {
        this.beforeDestroyCb.push(cb)
      }
    },
    mounted() {
      this.initFirstCalculate();

      const resizeCb = throttle(this.onResize, 30)
      window.addEventListener('resize', resizeCb);

      this.initAutoscroll();

      this.cleanOnDestroy(() => {
        window.removeEventListener('resize', resizeCb)
      })
    },
    beforeDestroy() {
      this.beforeDestroyCb.forEach(cb => cb())
    }

  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";
	@import "src/assets/css/mixins";

	.carousel {
		display: flex;
		gap: 1rem;
		position: relative;
		flex-direction: column;
	}

	.carousel_navigation {
		z-index: 2;
		align-self: flex-end;
		display: flex;
		gap: 1rem;
	}

	.carousel_wrapper {
		flex-grow: 1;
		position: relative;
		overflow: hidden;
	}

	.carousel_items {
		display: grid;
		transition: all 2s linear;

		& > * {
			overflow: hidden;
		}
	}

	.left_arrow,
	.right_arrow {
		background: transparent;
		padding: 0;
		width: 2rem;
		height: 1rem;

		svg {
			color: rgba($text-tertiary-middle-rgb, .5);
			transition: all .2s linear;
		}

		&:hover {
			svg {
				color: rgba($text-tertiary-middle-rgb, 1)
			}

			background: rgba($text-secondary-rgb, .8);
		}
	}

	.right_arrow {
		transform: rotateY(180deg);
	}

</style>

// TODO: брекпоинты